import React from "react"

import {
  Heading,
  List,
  ListItem,
  Stack,
  Text,
  TextLink,
} from "@kiwicom/orbit-components"
import { Phone } from "@kiwicom/orbit-components/icons"

import Container from "components/container"
import { EmberCard, EmberCardSection } from "components/generic/ember-card"
import { AdminLayout } from "components/layout-custom"

const Page = () => (
  <AdminLayout title="Actions">
    <Container size="medium">
      <Stack spacing="XLarge">
        <EmberCard icon={<Phone />} title="Reporting Tech Issues">
          <EmberCardSection>
            <Stack>
              <Text>
                Most technical issues should be reported via the{" "}
                <TextLink
                  external
                  href="https://mail.google.com/mail/u/0/#chat/space/AAAAHgu9j5Y"
                >
                  Tech channel in Google Chat
                </TextLink>
                .
              </Text>
              <Text>
                If there's an urgent issue requiring immediate assistance,
                report it via the{" "}
                <TextLink
                  external
                  href="https://betteruptime.com/report/rjGqXTk5VDPaNEVfMZu4H7Bs"
                >
                  incident form
                </TextLink>
                . Make sure to select "Immediately notify via call". This will
                trigger a phone call to the person who is currently on-call for
                technical issues. If you leave the call option unticked, the
                on-call person will get notified, but not woken up.
              </Text>
              <Text>
                Here are some examples of urgent vs. non-urgent issues:
              </Text>
              <Heading type="title3">Urgent</Heading>
              <List>
                <ListItem>
                  The website or API is completely down - no passengers are able
                  to book or check their tickets.
                </ListItem>
                <ListItem>
                  We are spamming loads of customers with emails or text
                  messages due to a tech fault.
                </ListItem>
                <ListItem>
                  The telematics are not updating, across multiple buses, so we
                  don't know where buses are.
                </ListItem>
              </List>
              <Heading type="title3">Not Urgent</Heading>
              <List>
                <ListItem>
                  A customer experienced a rare bug which prevented them from
                  booking. You should work around the issue to help the
                  passenger get their bus, then report it via Google Chat.
                </ListItem>
                <ListItem>
                  An external system which we have no control over goes down.
                  For example, SumUp readers on board buses are reporting a
                  SumUp server issue. Report it on Google Chat but most likely
                  we'll just have to wait for them to fix it.
                </ListItem>
              </List>
            </Stack>
          </EmberCardSection>
        </EmberCard>
      </Stack>
    </Container>
  </AdminLayout>
)

export default Page
